<template>
  <div class="container mt-5">
    <h2>Key詳細</h2>
    <form @submit.prevent="saveKey" class="row g-3">
      <div class="col-md-3">
        <label for="KEY_ID" class="form-label">KEY ID</label>
        <input id="KEY_ID" v-model="keyDetail.KEY_ID" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="KEY_VALUE" class="form-label">KEYバリュー</label>
        <input id="KEY_VALUE" v-model="keyDetail.KEY_VALUE" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="COMPANY_ID" class="form-label">会社ID</label>
        <input id="COMPANY_ID" v-model="keyDetail.COMPANY_ID" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="COMPANY_NAME" class="form-label">会社名</label>
        <input id="COMPANY_NAME" v-model="keyDetail.COMPANY_NAME" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="CREATION_DATE" class="form-label">作成日</label>
        <input id="CREATION_DATE" v-model="keyDetail.CREATION_DATE" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="STATUS" class="form-label">Key状態</label>
        <input id="STATUS" v-model="keyDetail.STATUS" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MAIL_PERMISSION" class="form-label">配信権限</label>
        <select id="MAIL_PERMISSION" v-model="keyDetail.MAIL_PERMISSION" class="form-select" :disabled="!isEditing">
          <option :value="true">あり</option>
          <option :value="false">なし</option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="MEISHI_PERMISSION" class="form-label">名刺管理権限</label>
        <select id="MEISHI_PERMISSION" v-model="keyDetail.MEISHI_PERMISSION" class="form-select" :disabled="!isEditing">
          <option :value="true">あり</option>
          <option :value="false">なし</option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="CURRENT_USAGE" class="form-label">名刺利用量</label>
        <input id="CURRENT_USAGE" v-model="keyDetail.CURRENT_USAGE" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="TOTAL_USAGE_LIMIT" class="form-label">名刺利用制限</label>
        <input id="TOTAL_USAGE_LIMIT" v-model="keyDetail.TOTAL_USAGE_LIMIT" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MAIL_USAGE" class="form-label">配信利用量</label>
        <input id="MAIL_USAGE" v-model="keyDetail.MAIL_USAGE" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="EXPIRATION_DATE" class="form-label">有効期限</label>
        <input id="EXPIRATION_DATE" v-model="keyDetail.EXPIRATION_DATE" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="KINTONE_SUBDOMAIN" class="form-label">Kintoneサブドメイン</label>
        <input id="KINTONE_SUBDOMAIN" v-model="keyDetail.KINTONE_SUBDOMAIN" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="KINTONE_API_ID" class="form-label">KintoneアプリID</label>
        <input id="KINTONE_API_ID" v-model="keyDetail.KINTONE_API_ID" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="KINTONE_API_TOKEN" class="form-label">KintoneAPIトークン</label>
        <input id="KINTONE_API_TOKEN" v-model="keyDetail.KINTONE_API_TOKEN" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="LINE_CHANNEL_SECRET" class="form-label">Lineシークレット</label>
        <input id="LINE_CHANNEL_SECRET" v-model="keyDetail.LINE_CHANNEL_SECRET" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="LINE_CHANNEL_ACCESS_TOKEN" class="form-label">Lineトークン</label>
        <input id="LINE_CHANNEL_ACCESS_TOKEN" v-model="keyDetail.LINE_CHANNEL_ACCESS_TOKEN" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MAIL_SERVER" class="form-label">STMPサーバ</label>
        <input id="MAIL_SERVER" v-model="keyDetail.MAIL_SERVER" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MAIL_PORT" class="form-label">STMPポート</label>
        <input id="MAIL_PORT" v-model="keyDetail.MAIL_PORT" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MAIL_USERNAME" class="form-label">STMPアカウント</label>
        <input id="MAIL_USERNAME" v-model="keyDetail.MAIL_USERNAME" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MAIL_PASSWORD" class="form-label">STMPパスワード</label>
        <input id="MAIL_PASSWORD" v-model="keyDetail.MAIL_PASSWORD" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-12">
        <button type="button" @click="isEditing = !isEditing" class="btn btn-primary me-2">{{ isEditing ? 'キャンセル' : '編集' }}</button>
        <button type="submit" v-if="isEditing" class="btn btn-success">保存</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from '@/axios'; // 使用创建的 axios 实例

export default {
  data() {
    return {
      keyDetail: {},
      isEditing: false
    };
  },
  created() {
    this.fetchKeyDetail(this.$route.params.key_id);
  },
  methods: {
    async fetchKeyDetail(key_id) {
      try {
        const response = await axios.get(`/api/keys/${key_id}`);
        this.keyDetail = response.data;
      } catch (error) {
        console.error('Keyを取得に失敗しました:', error);
      }
    },
    async saveKey() {
      try {
        // 直接传递布尔值
        await axios.put(`/api/keys/${this.keyDetail.KEY_ID}`, this.keyDetail);
        alert('Keyを保存しました。');
        this.fetchKeyDetail(this.keyDetail.KEY_ID);
        this.isEditing = false;
      } catch (error) {
        console.error('Keyを保存に失敗しました:', error);
        alert('Keyを保存に失敗しました');
      }
    }
  }
};
</script>

<!-- <template>
  <div class="container mt-5">
    <h2>Key詳細</h2>
    <form @submit.prevent="saveKey" class="row g-3">
      <div class="col-md-3">
        <label for="KEY_ID" class="form-label">KEY ID</label>
        <input id="KEY_ID" v-model="keyDetail.KEY_ID" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="KEY_VALUE" class="form-label">KEYバリュー</label>
        <input id="KEY_VALUE" v-model="keyDetail.KEY_VALUE" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="COMPANY_ID" class="form-label">会社ID</label>
        <input id="COMPANY_ID" v-model="keyDetail.COMPANY_ID" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="COMPANY_NAME" class="form-label">会社名</label>
        <input id="COMPANY_NAME" v-model="keyDetail.COMPANY_NAME" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="CREATION_DATE" class="form-label">作成日</label>
        <input id="CREATION_DATE" v-model="keyDetail.CREATION_DATE" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="STATUS" class="form-label">Key状態</label>
        <input id="STATUS" v-model="keyDetail.STATUS" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MAIL_PERMISSION" class="form-label">配信権限</label>
        <select id="MAIL_PERMISSION" v-model="keyDetail.MAIL_PERMISSION" class="form-select" :disabled="!isEditing">
          <option :value="true">あり</option>
          <option :value="false">なし</option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="MEISHI_PERMISSION" class="form-label">名刺管理権限</label>
        <select id="MEISHI_PERMISSION" v-model="keyDetail.MEISHI_PERMISSION" class="form-select" :disabled="!isEditing">
          <option :value="true">あり</option>
          <option :value="false">なし</option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="CURRENT_USAGE" class="form-label">名刺利用量</label>
        <input id="CURRENT_USAGE" v-model="keyDetail.CURRENT_USAGE" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="TOTAL_USAGE_LIMIT" class="form-label">名刺利用制限</label>
        <input id="TOTAL_USAGE_LIMIT" v-model="keyDetail.TOTAL_USAGE_LIMIT" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MAIL_USAGE" class="form-label">配信利用量</label>
        <input id="MAIL_USAGE" v-model="keyDetail.MAIL_USAGE" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="EXPIRATION_DATE" class="form-label">有効期限</label>
        <input id="EXPIRATION_DATE" v-model="keyDetail.EXPIRATION_DATE" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="KINTONE_SUBDOMAIN" class="form-label">Kintoneサブドメイン</label>
        <input id="KINTONE_SUBDOMAIN" v-model="keyDetail.KINTONE_SUBDOMAIN" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="KINTONE_API_ID" class="form-label">KintoneアプリID</label>
        <input id="KINTONE_API_ID" v-model="keyDetail.KINTONE_API_ID" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="KINTONE_API_TOKEN" class="form-label">KintoneAPIトークン</label>
        <input id="KINTONE_API_TOKEN" v-model="keyDetail.KINTONE_API_TOKEN" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="LINE_CHANNEL_SECRET" class="form-label">Lineシークレット</label>
        <input id="LINE_CHANNEL_SECRET" v-model="keyDetail.LINE_CHANNEL_SECRET" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="LINE_CHANNEL_ACCESS_TOKEN" class="form-label">Lineトークン</label>
        <input id="LINE_CHANNEL_ACCESS_TOKEN" v-model="keyDetail.LINE_CHANNEL_ACCESS_TOKEN" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MAIL_SERVER" class="form-label">STMPサーバ</label>
        <input id="MAIL_SERVER" v-model="keyDetail.MAIL_SERVER" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MAIL_PORT" class="form-label">STMPポート</label>
        <input id="MAIL_PORT" v-model="keyDetail.MAIL_PORT" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MAIL_USERNAME" class="form-label">STMPアカウント</label>
        <input id="MAIL_USERNAME" v-model="keyDetail.MAIL_USERNAME" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MAIL_PASSWORD" class="form-label">STMPパスワード</label>
        <input id="MAIL_PASSWORD" v-model="keyDetail.MAIL_PASSWORD" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-12">
        <button type="button" @click="isEditing = !isEditing" class="btn btn-primary me-2">{{ isEditing ? 'キャンセル' : '編集' }}</button>
        <button type="submit" v-if="isEditing" class="btn btn-success">保存</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from '@/axios'; // 使用创建的 axios 实例

export default {
  data() {
    return {
      keyDetail: {},
      isEditing: false
    };
  },
  created() {
    this.fetchKeyDetail(this.$route.params.key_id);
  },
  methods: {
    async fetchKeyDetail(key_id) {
      try {
        const response = await axios.get(`/api/keys/${key_id}`);
        this.keyDetail = response.data;
      } catch (error) {
        console.error('Keyを取得に失敗しました:', error);
      }
    },
    async saveKey() {
      try {
        // 直接传递布尔值
        await axios.put(`/api/keys/${this.keyDetail.KEY_ID}`, this.keyDetail);
        alert('Keyを保存しました。');
        this.fetchKeyDetail(this.keyDetail.KEY_ID);
        this.isEditing = false;
      } catch (error) {
        console.error('Keyを保存に失敗しました:', error);
        alert('Keyを保存に失敗しました');
      }
    }
  }
};
</script> -->

<!-- <template>
  <div class="container mt-5">
    <h2>Key詳細</h2>
    <form @submit.prevent="saveKey" class="row g-3">
      <div class="col-md-3">
        <label for="KEY_ID" class="form-label">KEY ID</label>
        <input id="KEY_ID" v-model="keyDetail.KEY_ID" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="KEY_VALUE" class="form-label">KEYバリュー</label>
        <input id="KEY_VALUE" v-model="keyDetail.KEY_VALUE" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="COMPANY_ID" class="form-label">会社ID</label>
        <input id="COMPANY_ID" v-model="keyDetail.COMPANY_ID" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="COMPANY_NAME" class="form-label">会社名</label>
        <input id="COMPANY_NAME" v-model="keyDetail.COMPANY_NAME" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="CREATION_DATE" class="form-label">作成日</label>
        <input id="CREATION_DATE" v-model="keyDetail.CREATION_DATE" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="STATUS" class="form-label">Key状態</label>
        <input id="STATUS" v-model="keyDetail.STATUS" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MAIL_PERMISSION" class="form-label">配信権限</label>
        <input id="MAIL_PERMISSION" v-model="keyDetail.MAIL_PERMISSION" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MEISHI_PERMISSION" class="form-label">名刺管理権限</label>
        <input id="MEISHI_PERMISSION" v-model="keyDetail.MEISHI_PERMISSION" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="CURRENT_USAGE" class="form-label">名刺利用量</label>
        <input id="CURRENT_USAGE" v-model="keyDetail.CURRENT_USAGE" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="TOTAL_USAGE_LIMIT" class="form-label">名刺利用制限</label>
        <input id="TOTAL_USAGE_LIMIT" v-model="keyDetail.TOTAL_USAGE_LIMIT" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MAIL_USAGE" class="form-label">配信利用量</label>
        <input id="MAIL_USAGE" v-model="keyDetail.MAIL_USAGE" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="EXPIRATION_DATE" class="form-label">有効期限</label>
        <input id="EXPIRATION_DATE" v-model="keyDetail.EXPIRATION_DATE" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="KINTONE_SUBDOMAIN" class="form-label">Kintoneサブドメイン</label>
        <input id="KINTONE_SUBDOMAIN" v-model="keyDetail.KINTONE_SUBDOMAIN" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="KINTONE_API_ID" class="form-label">KintoneアプリID</label>
        <input id="KINTONE_API_ID" v-model="keyDetail.KINTONE_API_ID" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="KINTONE_API_TOKEN" class="form-label">KintoneAPIトークン</label>
        <input id="KINTONE_API_TOKEN" v-model="keyDetail.KINTONE_API_TOKEN" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="LINE_CHANNEL_SECRET" class="form-label">Lineシークレット</label>
        <input id="LINE_CHANNEL_SECRET" v-model="keyDetail.LINE_CHANNEL_SECRET" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="LINE_CHANNEL_ACCESS_TOKEN" class="form-label">Lineトークン</label>
        <input id="LINE_CHANNEL_ACCESS_TOKEN" v-model="keyDetail.LINE_CHANNEL_ACCESS_TOKEN" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MAIL_SERVER" class="form-label">STMPサーバ</label>
        <input id="MAIL_SERVER" v-model="keyDetail.MAIL_SERVER" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MAIL_PORT" class="form-label">STMPポート</label>
        <input id="MAIL_PORT" v-model="keyDetail.MAIL_PORT" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MAIL_USERNAME" class="form-label">STMPアカウント</label>
        <input id="MAIL_USERNAME" v-model="keyDetail.MAIL_USERNAME" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-md-3">
        <label for="MAIL_PASSWORD" class="form-label">STMPパスワード</label>
        <input id="MAIL_PASSWORD" v-model="keyDetail.MAIL_PASSWORD" class="form-control" :disabled="!isEditing" />
      </div>
      <div class="col-12">
        <button type="button" @click="isEditing = !isEditing" class="btn btn-primary me-2">{{ isEditing ? 'キャンセル' : '編集' }}</button>
        <button type="submit" v-if="isEditing" class="btn btn-success">保存</button>
      </div>
      
    </form>
  </div>
</template>

<script>
import axios from '@/axios'; // 使用创建的 axios 实例

export default {
  data() {
    return {
      keyDetail: {},
      isEditing: false
    };
  },
  created() {
    this.fetchKeyDetail(this.$route.params.key_id);
  },
  methods: {
    async fetchKeyDetail(key_id) {
      try {
        const response = await axios.get(`/api/keys/${key_id}`);
        this.keyDetail = response.data;
      } catch (error) {
        console.error('Keyを取得に失敗しました:', error);
      }
    },
    async saveKey() {
      try {
        await axios.put(`/api/keys/${this.keyDetail.KEY_ID}`, this.keyDetail);
        alert('Keyを保存しました。');
        this.fetchKeyDetail(this.keyDetail.KEY_ID);
        this.isEditing = false;
      } catch (error) {
        console.error('Keyを保存に失敗しました:', error);
        alert('Keyを保存に失敗しました');
      }
    }
  }
};
</script> -->