<template>
  <div class="container mt-5">
    <h2>Key追加</h2>
    <form @submit.prevent="addKey" class="row g-3">
      <div class="col-md-6">
        <label for="company_id" class="form-label">会社ID</label>
        <input id="company_id" v-model="newKey.COMPANY_ID" class="form-control" required />
      </div>
      <div class="col-md-6">
        <label for="mail_permission" class="form-label">配信権限</label>
        <select id="mail_permission" v-model="newKey.MAIL_PERMISSION" class="form-select" required>
          <option :value="true">あり</option>
          <option :value="false">なし</option>
        </select>
      </div>
      <div class="col-md-6">
        <label for="meishi_permission" class="form-label">名刺管理権限</label>
        <select id="meishi_permission" v-model="newKey.MEISHI_PERMISSION" class="form-select" required>
          <option :value="true">あり</option>
          <option :value="false">なし</option>
        </select>
      </div>
      <div class="col-12">
        <button type="submit" class="btn btn-primary">Key追加</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from '@/axios'; // 使用创建的 axios 实例

export default {
  data() {
    return {
      newKey: {
        COMPANY_ID: '',
        MAIL_PERMISSION: false,  // 直接使用布尔值
        MEISHI_PERMISSION: false  // 直接使用布尔值
      }
    };
  },
  methods: {
    async addKey() {
      try {
        const response = await axios.post('/api/keys', this.newKey);
        alert(`Keyを追加しました。: ${JSON.stringify(response.data.key)}`);
        this.$router.push('/admin-dashboard/key-add');
      } catch (error) {
        console.error('Key追加に失敗しました:', error);
        if (error.response) {
          alert(`Key追加に失敗しました: ${error.response.data.error}`);
        } else if (error.request) {
          alert('Key追加に失敗しました: リクエストは送信されましたが応答はありません。');
        } else {
          alert('Key追加に失敗しました: 配置エラー。');
        }
      }
    }
  }
};
</script>

<!-- <template>
  <div class="container mt-5">
    <h2>Key追加</h2>
    <form @submit.prevent="addKey" class="row g-3">
      <div class="col-md-6">
        <label for="company_id" class="form-label">会社ID</label>
        <input id="company_id" v-model="newKey.COMPANY_ID" class="form-control" required />
      </div>
      <div class="col-md-6">
        <label for="mail_permission" class="form-label">配信権限</label>
        <select id="mail_permission" v-model="newKey.MAIL_PERMISSION" class="form-select" required>
          <option value="true">あり</option>
          <option value="false">なし</option>
        </select>
      </div>
      <div class="col-md-6">
        <label for="meishi_permission" class="form-label">名刺管理権限</label>
        <select id="meishi_permission" v-model="newKey.MEISHI_PERMISSION" class="form-select" required>
          <option value="true">あり</option>
          <option value="false">なし</option>
        </select>
      </div>
      <div class="col-12">
        <button type="submit" class="btn btn-primary">Key追加</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from '@/axios'; // 使用创建的 axios 实例

export default {
  data() {
    return {
      newKey: {
        COMPANY_ID: '',
        MAIL_PERMISSION: 'false',
        MEISHI_PERMISSION: 'false'
      }
    };
  },
  methods: {
    async addKey() {
      try {
        const response = await axios.post('/api/keys', this.newKey);
        alert(`Keyを追加しました。: ${JSON.stringify(response.data.key)}`);
        this.$router.push('/admin-dashboard/key-add');
      } catch (error) {
        console.error('Key追加に失敗しました:', error);
        if (error.response) {
          alert(`Key追加に失敗しました: ${error.response.data.error}`);
        } else if (error.request) {
          alert('Key追加に失敗しました: リクエストは送信されましたが応答はありません。');
        } else {
          alert('Key追加に失敗しました: 配置エラー。');
        }
      }
    }
  }
};
</script> -->